<template>
  <div class="company-profile">
    <Header/>
    <img src="@/assets/img/mobile/company_banner.png" alt="" class="banner">
    <h2 class="title">公司介绍</h2>
    <p>
      北京诺亦腾科技有限公司，是具有国际竞争力的智能感知与沉浸式交互技术创新者。小诺肌骨团队包含了医疗，体育和科技的行业专家，旨在用循证医学的方式，针对肌骨疼痛的患者，提供一套科学有效，个性化的测评和运动方案。
    </p>
    <h2 class="title">团队介绍</h2>
    <p>
      小诺肌骨团队隶属于北京诺亦腾科技有限公司，后者是具有国际竞争力的智能感知与沉浸式交互技术创新者。小诺肌骨团队包含了医疗，体育和科技的行业专家，旨在用循证医学的方式，针对肌骨疼痛的患者，提供一套科学有效，个性化的测评和运动方案。
    </p>
    <div class="honorary-awards">
      <h2 class="title">荣誉奖项</h2>
      <ul>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div class="honor">国家专精特新“小巨人”企业</div>
        </li>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div class="honor">北京市专精特新“小巨人”企业</div>
        </li>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div class="honor">北京市科学技术奖</div>
        </li>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div>
            <div class="honor">老年全周期康复技术体系与信息化管理研究</div>
            <span>国家老年疾病临床医学研究中心（华山）、复旦大学附属华山医院</span>
          </div>
        </li>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div>
            <div class="honor">人体促进健康个性化精准指导方案关键技术研究</div>
            <span>北京体育大学</span>
          </div>
        </li>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div>
            <div class="honor">基于虚拟现实技术的认知功能障碍老年人“手-脑协同“能力评估-干预数字疗法产品研发与临床应用</div>
            <span>北京医院</span>
          </div>
        </li>
        <li>
          <img src="@/assets/img/mobile/icon-honor.svg" alt="">
          <div>
            <div class="honor">基于数字孪生技术的青少年脊柱侧弯干预治疗研究方法的应用示范</div>
            <span>宁夏回族自治区总医院</span>
          </div>
        </li>
      </ul>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"

export default {
  name: 'CompanyProfile',
  components: { Header, Footer },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.company-profile {
  padding-top: 0.48rem;

  .banner {
    display: block;
    width: 100%;
    margin-bottom: 0.36rem;
  }

  h2.title {
    font-size: 0.18rem;
    line-height: 0.24rem;
    margin-bottom: 0.12rem;
    text-align: center;
    color: #00aebb;
  }

  p {
    font-size: 0.12rem;
    line-height: 0.24rem;
    color: #1a1a1a;
    padding: 0 0.3rem;
    margin-bottom: 0.24rem;
  }

  .honorary-awards {
    h2 {
      margin-bottom: 0.36rem;
    }

    ul {
      padding: 0 0.24rem 0.48rem;

      li {
        min-height: 0.67rem;
        padding-left: 0.24rem;
        display: flex;
        align-items: center;
        border-bottom: 0.01rem solid #eeeeee;

        > div {
          padding: 0.18rem 0;
        }

        img {
          width: 0.16rem;
          margin-right: 0.12rem;
        }

        .honor {
          line-height: 0.24rem;
          font-size: 0.14rem;
          font-weight: 500;
          color: #1a1a1a;
          margin-bottom: 0.06rem;
        }

        > .honor {
          margin-bottom: 0;
        }

        span {
          font-size: 0.1rem;
          color: #767676;
        }
      }
    }
  }
}
</style>